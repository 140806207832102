import { ComponentProps } from 'react'
import { ColorTokens, styled, theme } from 'stitches.config'

const color = Object.keys(theme.colors).reduce((acc, color) => {
  return { ...acc, [color]: { '&': { color: `$${color}` } } }
}, {}) as { [key in ColorTokens]: any }

const size = Object.keys(theme.fontSizes).reduce((acc, fontSize) => {
  return { ...acc, [fontSize]: { '&': { fontSize: `$${fontSize}`, lineHeight: `$${fontSize}` } } }
}, {}) as { [key in keyof typeof theme.fontSizes]: any }

export const Text = styled('span', {
  lineHeight: 1,
  color: 'currentColor',
  margin: 0,
  fontWeight: 400,
  fontVariantNumeric: 'tabular-nums',

  variants: {
    display: {
      inline: {
        display: 'inline-block',
      },
      block: {
        display: 'block',
      },
      none: {
        display: 'none',
      },
    },
    size: {
      ...size,
      h1: {
        '&': {
          fontSize: '$h3',
          lineHeight: '$h3',
          fontFamily: '$display',

          '@bp2': {
            fontSize: '$h1',
            lineHeight: '$h1',
          },
        },
      },

      h2: {
        '&': {
          fontSize: '$h4',
          lineHeight: '$h4',
          fontFamily: '$display',

          '@bp2': {
            fontSize: '$h2',
            lineHeight: '$h2',
          },
        },
      },

      h3: {
        fontFamily: '$display',
        lineHeight: '$h3',
        fontSize: '$h3',
      }
    },
    color,
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      medium: {
        fontWeight: '$medium',
      },
      bold: {
        fontWeight: '$medium',
      },
      700: {
        fontWeight: 700,
      },
      800: {
        fontWeight: 800,
      },
    },
    italics: {
      true: {
        fontStyle: 'italic',
      },
    },
    font: {
      primary: {
        fontFamily: '$primary',
      },
      display: {
        fontFamily: '$display',
      },
      label: {
        fontFamily: '$label',
      }
    },
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
      centerBp2: {
        textAlign: 'center',
        '@bp2': {
          textAlign: 'left',
        },
      },
    },

    truncate: {
      true: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    lineThrough: {
      true: {
        textDecoration: 'line-through',
        textDecorationColor: 'currentColor',
        textDecorationThickness: '3px',
      },
      thin: {
        textDecoration: 'line-through',
        textDecorationColor: 'currentColor',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline',
        textDecorationColor: 'currentColor',
      },
    },
    backgroundVariant: {
      current: {
        backgroundColor: 'grey',
      },
    },
    transform: {
      uppercase: {
        textTransform: 'uppercase',
      },
      lowercase: {
        textTransform: 'lowercase',
      },
      captialize: {
        textTransform: 'capitalize',
      },
    },
  },

  defaultVariants: {
    size: 'body',
    display: 'inline',
    font: 'primary'
  },
})

Text.displayName = 'Text'

export type TextProps = ComponentProps<typeof Text>
