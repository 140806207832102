export const setCookie = (key: CookieKeys, value: string) => {
  document.cookie = `${key}=${value};path=/`
}

export const getCookie = (key: CookieKeys) => {
  return document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(key))
    ?.replace(`${key}=`, '')
}

export const strictGetCookie = (key: CookieKeys) => {
  return document.cookie
    .split('; ')
    .find((cookie) => cookie?.split('=')[0] === key)
    ?.replace(`${key}=`, '')
}

export const removeCookie = (key: CookieKeys) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const setVidCookie = (name: string, value: string, domain: string) => {
  const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString()
  const secure = location.protocol === 'https:' ? 'Secure;' : ''
  const domainPart = domain ? `Domain=${domain};` : ''
  document.cookie = `${name}=${encodeURIComponent(
    value
  )};expires=${expires};path=/;${domainPart}${secure}SameSite=None;`
}

export const getVidCookie = (name: string) => {
  const cookies = document.cookie.split('; ')
  for (let cookie of cookies) {
    const [key, value] = cookie.split('=')
    if (key === name) return decodeURIComponent(value)
  }
  return null
}

export enum CookieKeys {
  _VID = 'wfr-_vid', // this cookie is set on the Instapage landing pages to transfer the VID initially set there
  SESSION = 'WFR_SESSION',
  GUEST_ID = 'WFR_GUEST_SESSION_ID',
  SHOPPING_CART_ID = 'WFR_SHOPPING_CART_ID',
  ONBOARDING_DONE = 'ONBOARDING_DONE',
  FBP = '_fbp',
  FBC = '_fbc',
  PROMO_CODE = 'PROMO_CODE',
  GA = '_ga',
  RECIPE_SELECTOR = 'RECIPE_SELECTOR',
}
