import axios from 'axios'
import { CookieKeys, getCookie, strictGetCookie } from 'services/Cookies'
import { Storage, StorageKeys } from 'services/Storage'
import { getClientId } from 'utils/getClientId'

import { SessionKeys, SessionStorage } from '../../services/Session'
export const fbConversion = async (
  type:
    | 'Register'
    | 'InitiateCheckout'
    | 'Subscription'
    | 'Purchase'
    | 'CustomizeProduct'
    | 'InitiateWizard'
    | 'ViewProducts',
  params?: any
) => {
  try {
    const clientId = type === 'Purchase' ? getClientId() : undefined
    const petAttemptId =
      type === 'Register' || type === 'CustomizeProduct' ? getClientId() : undefined
    const fbcCookie = getCookie(CookieKeys.FBC) ? getCookie(CookieKeys.FBC) : undefined
    const fbpCookie = getCookie(CookieKeys.FBP) ? getCookie(CookieKeys.FBP) : undefined
    const utmParams = Storage.get(StorageKeys.UTM_KEYS)
      ? Storage.get(StorageKeys.UTM_KEYS)
      : undefined
    const vistorId = getCookie(CookieKeys._VID)
      ? JSON.parse(decodeURIComponent(getCookie(CookieKeys._VID) || '')).value
      : undefined
    const petId = params?.pet?.petId ? params.pet.petId : undefined
    const subscriptionId = params?.subscription?.subscriptionId
      ? params.subscription.subscriptionId
      : undefined
    const total =
      params?.total ||
      (params?.subscription?.paymentDetails?.priceBreakdown?.total
        ? params.subscription.paymentDetails.priceBreakdown.total
        : undefined)
    const order = total ? { total_price_usd: total / 100 } : undefined
    const firstName = params?.firstName ? params.firstName : undefined
    const lastName = params?.lastName ? params.lastName : undefined
    const email = params?.email ? params.email : undefined
    const phone = params?.phoneNumber ? params.phoneNumber : undefined
    const zipCode = params?.shippingAddress?.zipCode ? params.shippingAddress.zipCode : undefined
    const state = params?.shippingAddress?.state ? params.shippingAddress.state : undefined
    const city = params?.shippingAddress?.city ? params.shippingAddress.city : undefined
    const country = params?.shippingAddress?.country ? params.shippingAddress.country : undefined
    const subType = type === 'ViewProducts' ? 'bulk' : params?.subType ? params.subType : undefined
    const orderId = params?.recentOrderId
      ? params.recentOrderId
      : params?.orderAbstract?.lastOrderId
      ? params.orderAbstract.lastOrderId
      : undefined
    const vwoExperiments = Storage.getExact(StorageKeys.VWO_EXPERIMENTS)
      ? Storage.getExact(StorageKeys.VWO_EXPERIMENTS)
      : undefined
    const versionFlag = Storage.get(StorageKeys.VERSION_FLAG)
      ? Storage.get(StorageKeys.VERSION_FLAG)
      : undefined
    const visitId =
      !!SessionStorage().getItem(SessionKeys.VISIT_ID) &&
      typeof SessionStorage().getItem(SessionKeys.VISIT_ID) === 'string'
        ? parseInt(SessionStorage().getItem(SessionKeys.VISIT_ID))
        : undefined
    const internalEvent = params?.internalEvent ? params.internalEvent : undefined
    const gaClientId = getGaClientId() || undefined
    const dailyDose = params?.dailyDose
      ? params.dailyDose
      : Storage.get(StorageKeys.DAILY_DOSE) || undefined
    const applePayEligible = Storage.get(StorageKeys.APPLE_PAY_ELIGIBLE)
      ? Storage.get(StorageKeys.APPLE_PAY_ELIGIBLE)
      : false
    const impactRadiusClickId = Storage.get(StorageKeys.IMPACT_RADIUS_CLICK_ID)
      ? Storage.get(StorageKeys.IMPACT_RADIUS_CLICK_ID)
      : undefined
    const promoCode = getCookie(CookieKeys.PROMO_CODE)
      ? getCookie(CookieKeys.PROMO_CODE)
      : Storage.get(StorageKeys.PROMO_CODE)
      ? Storage.get(StorageKeys.PROMO_CODE)
      : undefined
    const cart = params?.cart ? params.cart : {}
    const firstTimeBuyer = params?.clientTotalOrdersCount
      ? params?.clientTotalOrdersCount < 1
        ? 'Yes'
        : 'No'
      : params?.subscription?.clientTotalOrdersCount
      ? params?.subscription?.clientTotalOrdersCount < 1
        ? 'Yes'
        : 'No'
      : 'Yes'

    let payload: any = {
      event: type,
      url: document.location.href,
      clientId: clientId,
      visitorId: vistorId,
      subscriptionId: subscriptionId,
      order: order,
      petAttemptId: petAttemptId,
      petId: petId,
      fbc: fbcCookie,
      fbp: fbpCookie,
      utmParams: utmParams,
      lastName: lastName,
      firstName: firstName,
      email: email,
      zipCode: zipCode,
      phone: phone,
      country: country,
      state: state,
      city: city,
      cart,
      orderId: orderId,
      featureFlags: buildFlags(versionFlag),
      vwoFlags: vwoExperiments,
      visitId,
      internalEvent,
      gaClientId,
      dailyDose,
      applePayEligible,
      impactRadiusClickId,
      promoCode,
      subType,
      firstTimeBuyer,
    }
    await axios.post(`https://${process.env.NEXT_PUBLIC_ANALYTICS_API}/track/conversion`, payload)
    if (type === 'Purchase') Storage.remove(StorageKeys.DAILY_DOSE)
  } catch (err) {
    console.log('err', err)
    // Do nothing
  }
}

export const fbVisit = async () => {
  try {
    const landingPage = document.location.pathname
    const vistorId = getCookie(CookieKeys._VID)
      ? JSON.parse(decodeURIComponent(getCookie(CookieKeys._VID) || '')).value
      : undefined
    const fbcCookie = getCookie(CookieKeys.FBC) ? getCookie(CookieKeys.FBC) : undefined
    const promoCode = getCookie(CookieKeys.PROMO_CODE)
      ? getCookie(CookieKeys.PROMO_CODE)
      : Storage.get(StorageKeys.PROMO_CODE)
      ? Storage.get(StorageKeys.PROMO_CODE)
      : undefined
    const fbpCookie = getCookie(CookieKeys.FBP) ? getCookie(CookieKeys.FBP) : undefined
    const utmParams = Storage.get(StorageKeys.UTM_KEYS)
      ? Storage.get(StorageKeys.UTM_KEYS)
      : undefined
    const versionFlag = Storage.get(StorageKeys.VERSION_FLAG)
      ? Storage.get(StorageKeys.VERSION_FLAG)
      : undefined
    const gaClientId = getGaClientId() || undefined
    const applePayEligible = Storage.get(StorageKeys.APPLE_PAY_ELIGIBLE)
      ? Storage.get(StorageKeys.APPLE_PAY_ELIGIBLE)
      : false
    const impactRadiusClickId = Storage.get(StorageKeys.IMPACT_RADIUS_CLICK_ID)
      ? Storage.get(StorageKeys.IMPACT_RADIUS_CLICK_ID)
      : undefined
    const vwoExperiments = Storage.getExact(StorageKeys.VWO_EXPERIMENTS)
      ? Storage.getExact(StorageKeys.VWO_EXPERIMENTS)
      : undefined

    let payload: any = {
      visitorId: vistorId,
      url: document.location.href,
      fbc: fbcCookie,
      fbp: fbpCookie,
      utmParams: utmParams,
      landingPage: landingPage,
      promoCode: promoCode,
      featureFlags: buildFlags(versionFlag),
      vwoFlags: vwoExperiments,
      httpReferer: document.referrer,
      // trackGA4only // pass this only on second+ attempts. Not needed yet
      gaClientId,
      applePayEligible,
      impactRadiusClickId,
    }
    const { data } = await axios.post(
      `https://${process.env.NEXT_PUBLIC_ANALYTICS_API}/track/visit`,
      payload
    )
    if (data?.existingVisitorFound && data?.existingVisitorId) updateVID(data.existingVisitorId)
    if (data?.visitId) updateVisitId(data.visitId)
  } catch (err) {
    // Do nothing
  }
}

export const trackPageView = async (params: { contentName: string; contentCategory: string }) => {
  const fbcCookie = getCookie(CookieKeys.FBC) ? getCookie(CookieKeys.FBC) : undefined
  const fbpCookie = getCookie(CookieKeys.FBP) ? getCookie(CookieKeys.FBP) : undefined
  const visitId =
    !!SessionStorage().getItem(SessionKeys.VISIT_ID) &&
    typeof SessionStorage().getItem(SessionKeys.VISIT_ID) === 'string'
      ? parseInt(SessionStorage().getItem(SessionKeys.VISIT_ID))
      : undefined
  const vistorId = getCookie(CookieKeys._VID)
    ? JSON.parse(decodeURIComponent(getCookie(CookieKeys._VID) || '')).value
    : undefined

  //@TODO find out if I need this
  // const fbPid = getCookie(CookieKeys.FB_PID) ? getCookie(CookieKeys.FB_PID) : undefined

  const payload = {
    ...params,
    fbc: fbcCookie,
    fbp: fbpCookie,
    vistorId,
    visitId,
  }

  await axios.post(`https://${process.env.NEXT_PUBLIC_ANALYTICS_API}/track/view`, payload)
}

const updateVisitId = (vId: string | number) => {
  SessionStorage().setItem(SessionKeys.VISIT_ID, vId.toString())
}

const updateVID = (vId: string | number) => {
  const vidData = {
    value: vId || Storage.get(StorageKeys._VID),
    expiry: new Date().getTime(),
  }
  Storage.set(StorageKeys._VID, vidData)
}

const buildFlags = (featureFlags: any) => {
  if (!featureFlags) return ''
  return featureFlags && typeof featureFlags === 'object'
    ? Object.keys(featureFlags).reduce((sum, value) => {
        const delim = sum.length > 0 ? ',' : ''
        return (sum = sum + delim + featureFlags[value])
      }, '')
    : featureFlags
}

const getGaClientId = () => {
  return strictGetCookie(CookieKeys.GA)
}
