import Link from 'next/link'
import { Stack } from '../../../Stack'
import { CopyText, CopyWrapper } from '../../DiscountBanner.styles'

export const PromotionalBanner = (params: {
  disableClickPages: boolean
  displayAmount: string
}) => {
  const { disableClickPages, displayAmount } = params

  const content = (
    <CopyWrapper
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap="0"
      clickable={!disableClickPages}
    >
      <Stack direction={{ '@initial': 'column', '@bp3': 'row' }} gap="0">
        <CopyText>Get {displayAmount} Off</CopyText>
        <CopyText css={{ display: 'none', '@bp3': { display: 'inline' } }}>&nbsp;</CopyText>
        <CopyText>+ Free Lick Mat</CopyText>
      </Stack>
    </CopyWrapper>
  )

  return (
    <>
      {disableClickPages ? (
        content
      ) : (
        <Link href="/meal-plan" css={{ display: 'flex', width: '100%' }}>
          {content}
        </Link>
      )}
    </>
  )
}
