import { styled } from 'stitches.config'
import { Stack } from '../Stack'
import { Text } from '../Text'
export const DiscountBannerRoot = styled('header', {
  fontSize: '$body',
  lineHeight: '24px',
  margin: '0',
  position: 'relative',
  textAlign: 'center',
  zIndex: '5000000',
  fontWeight: '500',
  padding: '1rem 3rem',
  '& a': {
    textDecoration: 'none !important',
  },
  variants: {
    type: {
      blackFriday: {
        backgroundColor: '$accent-black',
        color: '$secondary-140',
      },
      promotion: {
        backgroundColor: '$accent-black',
        color: '$accent-white',
      },
      default: {
        backgroundColor: '$accent-black',
        color: '$accent-white',
      },
    },
  },
})

export const CloseButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  height: '32px',
  width: '32px',
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: 1,
  font: 'inherit',
  fontSize: '24px',
  transition: 'color 0.3s ease',
  variants: {
    hoverColor: {
      red: {
        '@bp2': {
          '&:hover': { color: '#D73D1F !important' },
        },
      },
    },
  },
})

export const CopyWrapper = styled(Stack, {
  variants: {
    clickable: {
      false: {
        cursor: 'default !important',
      },
      true: {
        cursor: 'pointer !important',
      },
    },
  },
})

export const CopyText = styled(Text, {
  fontSize: '$body !important',
  color: '$accent-white',
  variants: {
    blackFriday: {
      true: {
        '@bp3': {
          fontSize: '$headline !important',
        },
      },
    },
    color: {
      red: {
        color: '#D73D1F !important',
      },
    },
  },
})
