import Link from 'next/link'
import { Stack } from '../../../Stack'
import { CopyText, CopyWrapper } from '../../DiscountBanner.styles'

export const BlackFridayBanner = (params: {
  disableClickPages: boolean
  displayAmount: string
}) => {
  const { disableClickPages, displayAmount } = params

  const content = (
    <CopyWrapper
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="0"
      clickable={!disableClickPages}
    >
      <CopyText blackFriday>THE BLACK FRIDAY EVENT</CopyText>
      <Stack direction="column" justifyContent="center" alignItems="center" gap="0">
        <CopyText blackFriday color="red">
          Get {displayAmount} Off
        </CopyText>
        <CopyText blackFriday>(We never do this.)</CopyText>
      </Stack>
    </CopyWrapper>
  )

  return <>{disableClickPages ? content : <Link href="/meal-plan">{content}</Link>}</>
}
