import { Discount } from '../../../../api/queries/useFetchDiscountByCode'
import { Stack } from 'components/Stack'
import { CopyWrapper, CopyText } from '../../DiscountBanner.styles'
import Link from '@material-ui/core/Link'

export const DefaultBanner = (params: {
  code: any
  displayAmount: string
  displayText: string
  disableClickPages: boolean
  treatsForOneYear: string[]
  displayFreeCopy: string
}) => {
  const { code, displayAmount, displayText, disableClickPages, treatsForOneYear, displayFreeCopy } =
    params

  const nextBox = code === 'J1CA5C40'

  const isTreatsForOneYear = treatsForOneYear.includes(code)

  const content = (
    <CopyWrapper
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap="0"
      clickable={!disableClickPages}
    >
      {/* TREATS FOR ONE YEAR */}
      {isTreatsForOneYear ? (
        <Stack direction={{ '@initial': 'column', '@bp3': 'row' }} gap="0">
          <CopyText>Get FREE Treats for a Year</CopyText>
          <CopyText css={{ display: 'none', '@bp3': { display: 'inline' } }}>&nbsp;</CopyText>
          <CopyText>+ {displayAmount} off your first box!</CopyText>
        </Stack>
      ) : /* Nextbox Copy */
      nextBox ? (
        <Stack direction={{ '@initial': 'column', '@bp3': 'row' }} gap="0">
          <CopyText>Get {displayAmount} off your next box</CopyText>
          <CopyText css={{ display: 'none', '@bp3': { display: 'inline' } }}>&nbsp;</CopyText>
          <CopyText>{displayFreeCopy}</CopyText>
        </Stack>
      ) : /* Default */
      displayText ? (
        <Stack direction={{ '@initial': 'column', '@bp3': 'row' }} gap="0">
          <CopyText>{displayText}</CopyText>
          <CopyText css={{ display: 'none', '@bp3': { display: 'inline' } }}>&nbsp;</CopyText>
          <CopyText>{displayFreeCopy}</CopyText>
        </Stack>
      ) : (
        /* Fallback */
        <Stack direction={{ '@initial': 'column', '@bp3': 'row' }} gap="0">
          <CopyText>Free Shipping with Every Box!</CopyText>
        </Stack>
      )}
    </CopyWrapper>
  )

  return (
    <>
      {disableClickPages ? (
        content
      ) : (
        <Link href="/meal-plan" css={{ display: 'flex', width: '100%' }}>
          {content}
        </Link>
      )}
    </>
  )
}
