import { createStitches } from '@stitches/react'
import { FilterUnion } from 'types/FilterUnion'

const media = {
  mobile: '(min-width: 320px)',
  bp1: '(min-width: 520px)',
  bp1_1: '(min-width: 768px)',
  bp2: '(min-width: 900px)',
  bp2_1: '(min-width: 1024px)',
  bp3: '(min-width: 1200px)',
  bp3_1: '(min-width: 1420px)',
  bp4: '(min-width: 1800px)',
  bpInt: '(max-width: 767px)',
  bppsd: '(min-width: 1349px) and (max-width: 1350px)',
  bppsm:
    '(min-width: 411px) and (max-width: 412px) and (min-height: 822px) and (max-height: 823px)',
  motion: '(prefers-reduced-motion)',
  hover: '(any-hover: hover)',
  dark: '(prefers-color-scheme: dark)',
  light: '(prefers-color-scheme: light)',
} as const

export const {
  styled,
  css,
  getCssText,
  config,
  globalCss: stitchesGlobal,
  theme,
  keyframes,
} = createStitches({
  theme: {
    colors: {
      'primary-100': '#FFEBE9',
      'primary-200': '#FFB1A9',
      'primary-300': '#FF7768',
      'primary-400': '#EE4C3B',
      'primary-450': '#FF4620',
      'primary-500': '#EC3825',
      'primary-550': '#D73D1F',
      'primary-600': '#BD2D1E',
      'primary-650': '#871C20',
      'primary-700': '#A81607',
      'primary-800': '#5E160F',
      'primary-900': '#2F0B07',
      'secondary-100': '#FBF9F1',
      'secondary-140': '#FFFAF2',
      'secondary-150': '#F1EBD9',
      'secondary-200': '#FBF7ED',
      'secondary-300': '#F7F2E0',
      'secondary-400': '#F6F0DC',
      'secondary-450': '#ece6d3',
      'secondary-500': '#F4ECD3',
      'secondary-550': '#f2f0e7',
      'secondary-580': '#E7DDBE',
      'secondary-590': '#E5C07F',
      'secondary-600': '#DCD4BE',
      'secondary-650': '#D6CCAD',
      'secondary-700': '#C3BDA9',
      'secondary-800': '#928E7F',
      'secondary-900': '#49473F',
      'tertiary-100': '#EEEEEE',
      'tertiary-200': '#E2E2E2',
      'tertiary-250': '#D9D9D9',
      'tertiary-300': '#CFCFD2',
      'tertiary-350': '#AEAEAE',
      'tertiary-400': '#ADAEB2',
      'tertiary-500': '#999BA1',
      'tertiary-600': '#6E727E',
      'tertiary-700': '#555864',
      'tertiary-800': '#32353F',
      'tertiary-825': '#332A29',
      'tertiary-850': '#231A19',
      'tertiary-900': '#222328',
      'quaternary-50': '#CBC1B8',
      'quaternary-55': '#BEB3A9',
      'quaternary-75': '#8E7E71',
      'quaternary-85': '#D5D5D5',
      'quaternary-90': '#C7C7C7',
      'quaternary-100': '#848484',
      'quaternary-150': '#6A231E',
      'quaternary-175': '#C96732',
      'quaternary-200': '#57100B',
      'quaternary-240': '#1855A0',
      'quaternary-250': '#114680',
      'quaternary-260': '#0D3866',
      'quaternary-300': '#828793',
      'quaternary-400': '#717d68',
      'quaternary-500': '#f9cb9c',
      'quaternary-510': '#FFC9B2',
      'quaternary-515': '#FFB99A',
      'quaternary-520': '#F5A92D',
      'quaternary-525': '#E6B180',
      'quaternary-530': '#DFA06F',
      'quaternary-535': '#CDC19F',
      'quaternary-550': '#E0B39F',
      'quaternary-555': '#D5A28E',
      'quaternary-600': '#FFCB76',
      'success-100': '#EFFFF4',
      'success-500': '#05C574',
      'info-100': '#F5F5F5',
      'error-100': '#FFF6F6',
      'accent-white': '#fff',
      'accent-black': '#000',
      'accent-menu-bo': '#f9f9f9',
      'accent-red': '#D94934',
      'accent-account-submenu': '#F2F0E9',
      'tag-green': '5, 197, 116',
      'tag-green-color': 'rgb($tag-green)',
      'tag-blue': '37, 140, 236',
      'tag-blue-color': 'rgb($tag-blue)',
      'tag-yellow': '245, 169, 45',
      'tag-yellow-color': 'rgb($tag-yellow)',
      'tag-yellow-hover': '255, 189, 65',
      'tag-yellow-hover-color': 'rgb($tag-yellow-hover)',
      'tag-orange': '255, 153, 0',
      'tag-orange-color': 'rgb($tag-orange)',
      'tag-dark-orange': '201, 103, 50',
      'tag-dark-orange-color': 'rgb($tag-dark-orange)',
      'tag-dark-orange-hover': '217, 119, 66',
      'tag-dark-orange-hover-color': 'rgb($tag-dark-orange-hover)',
      'tag-red': '204, 42, 42',
      'tag-red-color': 'rgb($tag-red)',
      transparent: 'transparent',
    },
    space: {
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '40px',
      6: '48px',
      7: '56px',
      8: '64px',
      9: '72px',
      10: '80px',
      11: '88px',
      12: '96px',
      13: '104px',
      14: '112px',
      15: '120px',
    },
    fontSizes: {
      h1: '52px',
      h2: '44px',
      h3: '34px',
      h4: '28px',
      h5: '24px',
      headline: '20px',
      bodyExtraLarge: '22px',
      bodyLarge: '18px',
      body: '16px',
      small: '14px',
      caption: '12px',
    },
    lineHeights: {
      h1: '62px',
      h2: 1.22,
      h3: 1.31,
      h4: 1.42,
      h5: 1.25,
      headline: 1.5,
      body: '24px',
      small: 1.57,
      caption: 1.83,
    },
    fonts: {
      display: 'Beirut Display, serif',
      label: 'Spezia, sans-serif',
      primary: 'Spezia Wide, sans-serif'
    },
    fontWeights: {
      regular: 400,
      medium: 600,
      bold: 800,
    },
    letterSpacings: {
      h1: '-2%',
      body: '2%',
    },
    sizes: {
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '40px',
      6: '48px',
      7: '56px',
      8: '64px',
      9: '72px',
      10: '80px',
      11: '88px',
      12: '96px',
      13: '104px',
      14: '112px',
      15: '120px',
      max: '1440px',
    },
    borderWidths: {},
    borderStyles: {},
    radii: {
      1: '4px',
      2: '6px',
      3: '8px',
      4: '12px',
      6: '20px',
      round: '50%',
      pill: '9999px',
    },
    shadows: {},
    zIndices: {
      1: 100,
      2: 200,
      3: 300,
      4: 400,
      max: 999,
    },
    transitions: {},
  },
  media,
  utils: {
    size: (value: any) => ({
      width: value,
      height: value,
    }),
    px: (value: any) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: any) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    mx: (value: any) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: any) => ({
      marginTop: value,
      marginBottom: value,
    }),
  },
})

export const globalStyles = stitchesGlobal({
  '*': {
    boxSizing: 'border-box',
    textDecoration: 'none',
    listStyleType: 'none',
    color: 'inherit',
    padding: 0,
    margin: 0,
    scrollBehavior: 'smooth',
  },
  body: {
    fontFamily: '$primary',
  },
  h1: {
    fontFamily: '$display',
  },
  h2: {
    fontFamily: '$display',
  },
  h3: {
    fontFamily: '$display',
  },
  '@font-face': [
    {
      fontFamily: 'Beirut Display',
      fontWeight: 300,
      src: "url('/fonts/BeirutDisplay-Light.woff2') format('woff2'), url('/fonts/BeirutDisplay-Light.woff') format('woff')",
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Beirut Display',
      fontWeight: 300,
      src: "url('/fonts/BeirutDisplay-LightItalic.woff2') format('woff2'), url('/fonts/BeirutDisplay-LightItalic.woff') format('woff')",
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia Wide',
      fontWeight: 500,
      src: "url('/fonts/SpeziaWide-Medium.woff2') format('woff2'), url('/fonts/SpeziaWide-Medium.woff') format('woff')",
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia Wide',
      fontWeight: 500,
      src: "url('/fonts/SpeziaWide-MediumItalic.woff2') format('woff2'), url('/fonts/SpeziaWide-MediumItalic.woff') format('woff')",
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia Wide',
      fontWeight: 600,
      src: "url('/fonts/SpeziaWide-SemiBold.woff2') format('woff2'), url('/fonts/SpeziaWide-SemiBold.woff') format('woff')",
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia Wide',
      fontWeight: 600,
      src: "url('/fonts/SpeziaWide-SemiBoldItalic.woff2') format('woff2'), url('/fonts/SpeziaWide-SemiBoldItalic.woff') format('woff')",
      fontStyle: 'italic',
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia',
      fontWeight: 500,
      src: "url('/fonts/SpeziaCondensed-Medium.otf') format('otf')",
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia',
      fontWeight: 600,
      src: "url('/fonts/SpeziaCondensed-SemiBold.otf') format('otf')",
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'Spezia',
      fontWeight: 700,
      src: "url('/fonts/SpeziaCondensed-Bold.otf') format('otf')",
      fontDisplay: 'swap',
    }
  ],
  '.hide-gorgias-on-mobile #gorgias-chat-container': {
    display: 'none',
    '@bp2': {
      display: 'initial',
    },
  },
  '.hide-accessibility-on-mobile #ae_app': {
    display: 'none !important',
    '@bp2': {
      display: 'initial',
    },
  },
  '.move-accessibility-on-mobile #ae_launcher': {
    bottom: '75px !important',
    '@bp2': {
      bottom: '24px !important',
    },
  },
})

export type ColorTokens = keyof typeof theme.colors
export type SizeTokens = keyof typeof theme.sizes
export type SpaceTokens = keyof typeof theme.space
export type MediaTokens = keyof typeof media

export type FlattenVariant<A> = FilterUnion<A, string>
